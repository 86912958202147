<template>
  <div class="box">
    <div class="left leftDetail">
      <img class="leftBg" v-if="!id" src="../assets/images/Consultation.png">
      <img class="leftBg" v-if="id == 1" src="../assets/images/Consultation1.png">
      <img class="leftBg" v-if="id == 2" src="../assets/images/Consultation2.png">
      <div class="leftImg">
        <navHead />
        <div class="leftTxt" v-if="!id">
          Consultation
        </div>
        <div class="leftTxt size38" v-if="id == 1">
          Technical Service
        </div>
        <div class="leftTxt size38" v-if="id == 2">
          Commercial  Service
        </div>
      </div>
    </div>
    <div class="right rightDetail" :class="{'detail' : id}">
      <div class="overScroll">
        <div class="rightTitle" v-if="!id">
          <p>MISSION AND AIMS: The client always comes first.</p>
          <p>What we provide is unrivalled expertise, customer service and support. </p>
        </div>
        <div class="rightTitle" v-if="id == 1">NON-DESTRUCTIVE TESTING consultancy regarding:</div>
        <div class="ul" v-if="id == 1">
          <p>●  The development of NDT methods</p>
          <p>●  Analysis of defects</p>
          <p>●  TKY joints and ultrasonic (UT) inspections.</p>
        </div>
        <div class="rightTitle" v-if="id == 1">COMMISSIONING:</div>
        <div class="ul" v-if="id == 1">
          <p>●  Adjusting components during testing; debugging.  </p>
        </div>
        <div class="rightTitle" v-if="id == 1">DOCUMENTING AND GUIDANCE TESTING for:</div>
        <div class="ul" v-if="id == 1">
          <p>●  Welding Process Qualification record (PQR)</p>
          <p>●  Welding Process Specification (WPS)</p>
        </div>
        <div class="rightTitle" v-if="id == 1">QUALITY DOCUMENTATION including:</div>
        <div class="ul" v-if="id == 1">
          <p>●  Inspection procedures</p>
          <p>●  Inspection and testing plans (ITP)</p>
          <p>●  Quality control plans (QCP)</p>
          <p>●  Inspection reports including</p>
          <span>■  Non-Conformist Report (NCR)</span>
          <span>■  Punch lists</span>
        </div>
        <div class="rightTitle" v-if="id == 1">DESTRUCTIVE TESTS: a comprehensive range including:</div>
        <div class="ul" v-if="id == 1">
          <p>●  Physical</p>
          <p>●  Chemical analysis</p>
          <p>●  Macroscopic</p>
          <p>●  Microscopic</p>
          <p>●  Impact</p>
          <p>●  Hardness </p>
          <p>●  Positive material identification</p>
          <p>●  Crack Tip Opening Displacement (CTOD)</p>
        </div>
        <div class="rightTitle" v-if="id == 1">CHINA TO EUROPEAN STANDARDS AND CODES CONVERSION: </div>
        <div class="ul" v-if="id == 1">
          <p>LIONOX specializes in international standards and codes conversion in two directions, using the </p>
          <p>●  We push the manufacturer to focus on detail of the client’s orders and requirements.</p>
          <p>●  We provide extra labour and equipment, if required, in order to keep schedules.</p>
        </div>
        <div class="rightTitle" v-if="id == 1">TRAINING AND QUALIFICATION: LIONOX arranges full training for:</div>
        <div class="ul" v-if="id == 1">
          <p>●  NDT inspectors</p>
          <p>●  Welding inspectors</p>
          <p>●  Welders </p>
        </div>
        <div class="rightTitle" v-if="id == 1">In accordance with:</div>
        <div class="ul" v-if="id == 1">
          <p>●  American Welding Society</p>
          <p>●  American Society of Mechanical Engineers</p>
          <p>●  International Organisation for Standardisation</p>
          <p>●  Certification Scheme for Welding Inspection Personnel</p>
          <p>●  Chinese standards for steel structures, pressure vessels and pipelines</p>
        </div>

        <div class="rightTitle" v-if="id == 2">BUSINESS EXPANSION</div>
        <div class="rightTitle" v-if="id == 2">We have significant experience of helping businesses expand into the Chinese market.</div>
        <div class="rightTitle" v-if="id == 2">Through our expertise and high performance, we help your business maximize its profit margins and, through client-focused consultancy services, specifically support, to the highest standards, your international expansion into the China-Asian Pacific market.</div>
        <div class="ul" v-if="id == 2">
          <p>●  Business strategy and development</p>
          <p>●  Supplement and value chain management</p>
          <p>●  Manufacture audit</p>
          <p>●  Early-stage project investigation</p>
          <p>●  A comprehensive customised service where the client always comes first</p>
        </div>

        <div class="rightUl" v-if="!id">
          <router-link to="/Consultation?id=1">Technical service</router-link>
          <router-link to="/Consultation?id=2">Commercial service</router-link>
        </div>
        <div class="rightTitle" v-if="!id">
          <p>We understand that growing a business, and making it successful in a competitive field, requires the best possible resources and consultancy services.  We aim to use our expertise and first-class provision of services to help you develop exceptional business performance and branding by tailoring our strengths to your needs. </p>
          <p>Our mission is to provide the most efficient and realistic solutions to your business challenges so your productivity, product quality, cost effectiveness, value and supply chains are notably enhanced, thereby significantly boosting your general business performance. </p>
          <p>We are Competitive, Focused, Dedicated, Caring.  </p>
        </div>
      </div>
      <div class="goBack" @click="goBack" v-if="id">
        <span>Back to previous page</span>
      </div>
    </div>
  </div>
</template>

<script>

import navHead from '/src/components/navHead'

export default {
  name: 'Controuter',
  components: {
    navHead
  },
  data(){
    return{
      id: null
    }
  },
  mounted(){
    this.id = this.$route.query.id || null
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.rightDetail.detail{
  padding:0;
}
.rightTitle{
  padding-top: 30px;
}
.rightUl{
  padding-top: 30px;
}
.rightDetail .ul{
  font-size: 18px!important;
  padding: 20px 0;
}
.rightDetail .ul span{
  display: block;
  padding-left: 40px;
}
</style>
